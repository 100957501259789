import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { id: "app-header" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "card jh-card" }
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ribbon = _resolveComponent("ribbon")!
  const _component_jhi_navbar = _resolveComponent("jhi-navbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_login_form = _resolveComponent("login-form")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_jhi_footer = _resolveComponent("jhi-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ribbon),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_jhi_navbar)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ]),
      _createVNode(_component_b_modal, {
        id: "login-page",
        "hide-footer": "",
        lazy: ""
      }, {
        "modal-title": _withCtx(() => [
          _createElementVNode("span", {
            "data-cy": "loginTitle",
            id: "login-title",
            textContent: _toDisplayString(_ctx.t$('login.title'))
          }, null, 8 /* PROPS */, _hoisted_5)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_login_form)
        ], undefined, true),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_jhi_footer)
    ])
  ]))
}