import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const ClientesView = () => import('@/entities/cliente/clientes-view.vue');
const ClienteUpdate = () => import('@/entities/cliente/cliente-update-view.vue');

const ClienteAndAlumnosEdit = () => import('@/entities/cliente/cliente-with-alumnos-view.vue');
const ClienteDetails = () => import('@/entities/cliente/cliente-details-view.vue');

const Matricula = () => import('@/entities/matricula/matricula.vue');
const MatriculaUpdate = () => import('@/entities/matricula/matricula-update.vue');
const MatriculaDetails = () => import('@/entities/matricula/matricula-details.vue');

const Recibo = () => import('@/entities/recibo/recibo.vue');
const ReciboUpdate = () => import('@/entities/recibo/recibo-update.vue');
const ReciboDetails = () => import('@/entities/recibo/recibo-details.vue');
const ReciboDownload = () => import('@/entities/recibo/recibo-download.vue');

const Factura = () => import('@/entities/factura/factura.vue');
const FacturaUpdate = () => import('@/entities/factura/factura-update.vue');
const FacturaDetails = () => import('@/entities/factura/factura-details.vue');

const Alumno = () => import('@/entities/alumno/alumno.vue');
const AlumnoUpdate = () => import('@/entities/alumno/alumno-update-view.vue');
const AlumnoDetails = () => import('@/entities/alumno/alumno-details-view.vue');

const Grupo = () => import('@/entities/grupo/grupo.vue');
const GrupoUpdate = () => import('@/entities/grupo/grupo-update.vue');
const GrupoDetails = () => import('@/entities/grupo/grupo-details.vue');

const Asistencia = () => import('@/entities/asistencia/asistencia.vue');
const AsistenciaUpdate = () => import('@/entities/asistencia/asistencia-update.vue');
const AsistenciaDetails = () => import('@/entities/asistencia/asistencia-details.vue');

const Profesor = () => import('@/entities/profesor/profesor.vue');
const ProfesorUpdate = () => import('@/entities/profesor/profesor-update.vue');
const ProfesorDetails = () => import('@/entities/profesor/profesor-details.vue');

const Aula = () => import('@/entities/aula/aula.vue');
const AulaUpdate = () => import('@/entities/aula/aula-update.vue');
const AulaDetails = () => import('@/entities/aula/aula-details.vue');

const Incidencia = () => import('@/entities/incidencia/incidencia.vue');
const IncidenciaUpdate = () => import('@/entities/incidencia/incidencia-update.vue');
const IncidenciaDetails = () => import('@/entities/incidencia/incidencia-details.vue');

// jhipster-needle-add-entity-to-router-import -  Enhouse billing will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'cliente',
      name: 'ClientesView',
      component: ClientesView,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cliente/new',
      name: 'ClienteCreate',
      component: ClienteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cliente/:clienteId/edit',
      name: 'ClienteEdit',
      component: ClienteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cliente/:clienteId/alumnos',
      name: 'ClienteAndAlumnosEdit',
      component: ClienteAndAlumnosEdit,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'cliente/alumnos/new',
      name: 'ClienteAndAlumnosCreate',
      component: ClienteAndAlumnosEdit,
      meta: { authorities: [Authority.USER] },
    },

    {
      path: 'cliente/:clienteId/view',
      name: 'ClienteView',
      component: ClienteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matricula',
      name: 'Matricula',
      component: Matricula,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matricula/new',
      name: 'MatriculaCreate',
      component: MatriculaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matricula/:matriculaId/edit',
      name: 'MatriculaEdit',
      component: MatriculaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matricula/:matriculaId/view',
      name: 'MatriculaView',
      component: MatriculaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'recibo',
      name: 'Recibo',
      component: Recibo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'recibo/new',
      name: 'ReciboCreate',
      component: ReciboUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'recibo/:reciboId/edit',
      name: 'ReciboEdit',
      component: ReciboUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'recibo/:reciboId/view',
      name: 'ReciboView',
      component: ReciboDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'recibo/:reciboId/download',
      name: 'ReciboView',
      component: ReciboDownload,
    },
    {
      path: 'factura',
      name: 'Factura',
      component: Factura,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'factura/new',
      name: 'FacturaCreate',
      component: FacturaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'factura/:facturaId/edit',
      name: 'FacturaEdit',
      component: FacturaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'factura/:facturaId/view',
      name: 'FacturaView',
      component: FacturaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'alumno',
      name: 'Alumno',
      component: Alumno,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'alumno/new',
      name: 'AlumnoCreate',
      component: AlumnoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'alumno/:alumnoId/edit',
      name: 'AlumnoEdit',
      component: AlumnoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'alumno/:alumnoId/view',
      name: 'AlumnoView',
      component: AlumnoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'grupo',
      name: 'Grupo',
      component: Grupo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'grupo/new',
      name: 'GrupoCreate',
      component: GrupoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'grupo/:grupoId/edit',
      name: 'GrupoEdit',
      component: GrupoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'grupo/:grupoId/view',
      name: 'GrupoView',
      component: GrupoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'asistencia',
      name: 'Asistencia',
      component: Asistencia,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'asistencia/new',
      name: 'AsistenciaCreate',
      component: AsistenciaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'asistencia/:asistenciaId/edit',
      name: 'AsistenciaEdit',
      component: AsistenciaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'asistencia/:asistenciaId/view',
      name: 'AsistenciaView',
      component: AsistenciaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profesor',
      name: 'Profesor',
      component: Profesor,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profesor/new',
      name: 'ProfesorCreate',
      component: ProfesorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profesor/:profesorId/edit',
      name: 'ProfesorEdit',
      component: ProfesorUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profesor/:profesorId/view',
      name: 'ProfesorView',
      component: ProfesorDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'aula',
      name: 'Aula',
      component: Aula,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'aula/new',
      name: 'AulaCreate',
      component: AulaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'aula/:aulaId/edit',
      name: 'AulaEdit',
      component: AulaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'aula/:aulaId/view',
      name: 'AulaView',
      component: AulaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incidencia',
      name: 'Incidencia',
      component: Incidencia,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incidencia/new',
      name: 'IncidenciaCreate',
      component: IncidenciaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incidencia/:incidenciaId/edit',
      name: 'IncidenciaEdit',
      component: IncidenciaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'incidencia/:incidenciaId/view',
      name: 'IncidenciaView',
      component: IncidenciaDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router -  Enhouse billing will add entities to the router here
  ],
};
