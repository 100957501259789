import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info jhi-item-count" }
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      textContent: _toDisplayString(_ctx.t$('global.item-count', { first: _ctx.first, second: _ctx.second, total: _ctx.total }))
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}