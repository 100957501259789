import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["textContent"]
const _hoisted_7 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_dropdown_item, { to: "/cliente" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.cliente'))
        }, null, 8 /* PROPS */, _hoisted_1)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/alumno" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.alumno'))
        }, null, 8 /* PROPS */, _hoisted_2)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/grupo" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.grupo'))
        }, null, 8 /* PROPS */, _hoisted_3)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/aula" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.aula'))
        }, null, 8 /* PROPS */, _hoisted_4)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/profesor" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.profesor'))
        }, null, 8 /* PROPS */, _hoisted_5)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/matricula" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.matricula'))
        }, null, 8 /* PROPS */, _hoisted_6)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_b_dropdown_item, { to: "/recibo" }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "asterisk" }),
        _createElementVNode("span", {
          textContent: _toDisplayString(_ctx.t$('global.menu.entities.recibo'))
        }, null, 8 /* PROPS */, _hoisted_7)
      ], undefined, true),
      _: 1 /* STABLE */
    }),
    _createCommentVNode("<b-dropdown-item to=\"/factura\">\n      <font-awesome-icon icon=\"asterisk\" />\n      <span v-text=\"t$('global.menu.entities.factura')\"></span>\n    </b-dropdown-item>\n    <b-dropdown-item to=\"/asistencia\">\n      <font-awesome-icon icon=\"asterisk\" />\n      <span v-text=\"t$('global.menu.entities.asistencia')\"></span>\n    </b-dropdown-item>\n    <b-dropdown-item to=\"/incidencia\">\n      <font-awesome-icon icon=\"asterisk\" />\n      <span v-text=\"t$('global.menu.entities.incidencia')\"></span>\n    </b-dropdown-item>"),
    _createCommentVNode(" jhipster-needle-add-entity-to-menu -  Enhouse billing will add entities to the menu here ")
  ]))
}