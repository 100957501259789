import { defineComponent } from 'vue';

export default defineComponent({
  compatConfig: { MODE: 3 },
  name: 'JhiSortIndicatorComponent',
  props: {
    currentOrder: String,
    fieldName: String,
    reverse: Boolean,
  },
});
